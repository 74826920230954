/* eslint-disable */
<template>
  <div class="error-page">
    <h1>Error</h1>
    <p>
      Произошла ошибка. Вы не имеете необходимых ролей для доступа к этой
      странице.
    </p>
    <v-btn @click="handleReturnAndSignOut"
      >Вернуться на главную страницу и Выход</v-btn
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";
/* eslint-disable */
import { getAuth, signOut } from "firebase/auth";
/* eslint-disable */
export default {
  name: "ErrorPage",
  methods: {
    ...mapActions(["emptyUser"]),
    async handleReturnAndSignOut() {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          /* eslint-disable */
          await signOut(auth); // Вызываем функцию без точки с запятой и без .apply
          this.emptyUser();
        } else {
          console.log("Пользователь не залогинен.");
        }

        // Добавляем задержку перед перенаправлением
        const delayMilliseconds = 2000; // Пример: 2 секунды
        setTimeout(() => {
          this.$router.push({ name: "Login" }); // Перенаправляем после задержки
        }, delayMilliseconds);
      },
  },
};
</script>

<style scoped>
.error-page {
  max-width: 600px;
  margin: auto;
  text-align: center;
  padding: 40px;
  font-family: Arial, sans-serif;
}
</style>
